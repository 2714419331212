import React, { Component } from 'react';
import './Layout.css'
import { NavMenu } from './NavMenu';
import Footer from './Footer';


export class Layout extends Component {
    static displayName = Layout.name;
    //adds the navbar to the webpage currently being used, and is then exported to App.js to display everything
    //written in class based react, this should be converted to hook based sometime, but is a very low priority
    render() {
        return (
            <div>
                <NavMenu />
                <div id="page">
                    {this.props.children}
                </div>
                <Footer/>
            </div>
        );
    }
}
