import React from 'react'
import constructionImage from './images/website-under-construction.gif'

//maintenance splash screen
export function Maintenance() {
    return (
        <div id="main" style={{ position: 'absolute', left: '5px', overflow: 'auto', top: '150px', width: '99%', border: '0px solid #CCCCCC' }}>
            <table style={{ width: '100%', textAlign: 'center', border: '0px solid #CCCCCC' }}>
                <tr>
                    <td style={{ border: '0px solid #CCCCCC' }}>
                        <img src={constructionImage} alt="Website under construction" style={{ border: '0px solid #CCCCCC' }} />
                    </td>
                </tr>
            </table>

            <div id="footer">
                <div id="fa" style={{ left: '0px', position: 'fixed', width: '100%', height: '20px', background: '#2B42A1', fontFamily: 'verdana', fontSize: '11px', bottom: '0%', textAlign: 'center', color: '#FFFFFF' }}>
                    <b>All contents of this site are �2013 American Airlines Inc.</b>
                </div>
            </div>
        </div>
    );
}

export default Maintenance;