import React, { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './MyCases.css';
import Loading from './Loading';
import DialogOk from './DialogOK';
import Footer from './Footer';

import Cookies from 'js-cookie';
import { getApiUrl } from './ApiUtil';

export function MyCases() {

    const navigate = useNavigate();
    const location = useLocation();
    let  internalId  = localStorage.getItem('internalId')
    if (location.state != null) {
        internalId = location.state.internalId;
    }

    //changes the target url for API calls when running locally
    const apiUrl = getApiUrl();

    const [filterText, setfilterText] = useState('Show Filters▼');

    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);

    const [statusOptions, setStatusOptions] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const entriesPerPage = 5;

    const [sortColumn, setSortColumn] = useState('lastUpdated');
    const [sortDirection, setSortDirection] = useState('desc');

    const [displayloadingDialog, setdisplayloadingDialog] = useState(true);
    const [displayOk, setdisplayOk] = useState(false);

    async function getData() {
        if (internalId === "" || internalId === null) {
            internalId = localStorage.getItem("internalId")
        }
        const response = await fetch(`${apiUrl}/status/?internalId=` + + internalId, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`
            }
        });
        return await response.text();
    }
    const ok = () => {
        setdisplayOk(false);
    };

    //Gets user case data from StatusController then parses and stores it
    useEffect(() => {
        async function fetchData() {
            setdisplayloadingDialog(true);


                let response = await getData();
                response = JSON.parse(response);
                const statusArray = [];
                const tableArray = [];
                for (var i = 0; i < response.length; i++) {



                    //parses the date information to be readable
                    const date = new Date(response[i].lastUpdated);
                    const lastUpdated = date.toDateString()
                    const caseNumber = response[i].number;
                    const subject = response[i].subject;
                    const status = response[i].status;
                    const objectID = response[i].objectId
                    if (!statusArray.includes(status)) {
                        statusArray.push(status);

                    }
                    tableArray.push({
                        caseNumber, subject, lastUpdated, status, objectID
                    });
                }
                setStatusOptions(statusArray);
                setTableData(tableArray);
                setData(tableArray);
                setdisplayloadingDialog(false);

            }
            fetchData();
    }, [internalId]);

    //state variables for sorting cases
    const [date1, setDate1] = useState(NaN);
    const [date2, setDate2] = useState(NaN);
    const [subject, setSubject] = useState('');
    const [status, setStatus] = useState('');
    const [caseNumber, setCaseNumber] = useState('');
    const [visible, setVisible] = useState(false);
    const dateInputRef = useRef(null);

    const handleChangeDate1 = (e) => {
        const testDate = new Date(e.target.value).getTime();
        setDate1(testDate);
    };
    const handleChangeDate2 = (e) => {
        const testDate = new Date(e.target.value).getTime();
        setDate2(testDate);
    };
    const handleChangeSubject = (e) => {
        setSubject(e.target.value);
    };
    const handleChangeStatus = (e) => {
        setStatus(e.target.value);
    };
    const handleChangeCaseNumber = (e) => {
        setCaseNumber(e.target.value);
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            //If the same column is clicked, toggle the sort direction
            setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
        } else {
            //If a different column is clicked, set the new column and default to ascending sort direction
            setSortColumn(column);
            setSortDirection('asc');
        }
        setCurrentPage(1);
    };

    const sortedEntries = [...tableData].sort((a, b) => {
        if (sortColumn === 'caseNumber') {
            return sortDirection === 'asc' ? a.caseNumber.localeCompare(b.caseNumber) : b.caseNumber.localeCompare(a.caseNumber);
        } else if (sortColumn === 'subject') {
            return sortDirection === 'asc' ? a.subject.localeCompare(b.subject) : b.subject.localeCompare(a.subject);
        } else if (sortColumn === 'lastUpdated') {
            const dateA = new Date(a.lastUpdated).getTime();
            const dateB = new Date(b.lastUpdated).getTime();
            return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
        } else if (sortColumn === 'status') {
            return sortDirection === 'asc' ? customSort(a, b) : customSort(b, a);
        }
        return 0;
    });
    function customSort(a, b) {
        if (a.status.includes('Pending') && !b.status.includes('Pending')) {
            return -1;
        } else if (!a.status.includes('Pending') && b.status.includes('Pending')) {
            return 1;
        } else if (a.status.includes('Completed') && !b.status.includes('Completed')) {
            return -1;
        } else if (!a.status.includes('Completed') && b.status.includes('Completed')) {
            return 1;
        } else {
            return a.status.localeCompare(b.status);
        }
    }
    const totalPages = Math.ceil(tableData.length / entriesPerPage);
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const displayedEntries = sortedEntries.slice(indexOfFirstEntry, indexOfLastEntry);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    function handleFilterClick() {
        setVisible(!visible)
        if (filterText === 'Show Filters▼') {
            setfilterText('Hide Filters▲');

        } else {
            setfilterText('Show Filters▼');

        }
        setDate1(NaN);
        setDate2(NaN);
        setSubject('');
        setStatus('');
        setCaseNumber('');
        setTableData(data);
        setCurrentPage(1);
    }
    function isEmpty(value) {
        return value === '' || value === null;
    }

    //filter's cases using user input
    function handleApplyClick() {
        const filtered = data.filter((row) => {
            //declare seperate dates to increase dateTo by 1 day on each iteration of filter, otherwise no cases will be shown when using matching dates
            let dateFrom = date1;
            let dateTo = date2;
            const lastUpdatedDatePre = new Date(row.lastUpdated);
            const lastUpdatedDate = lastUpdatedDatePre.getTime();

            if (dateFrom > dateTo) {
                setdisplayOk(true);
                return true
            } else {
                //Check if the row matches the filter criteria
                let matchesDateRange = true;
                if (!isNaN(dateTo)) {
                    dateTo += 86400000
                }
                if (!isNaN(dateTo) && !isNaN(dateFrom)) {
                    matchesDateRange = lastUpdatedDate >= dateFrom && lastUpdatedDate <= dateTo;
                }

                const matchesSubject = isEmpty(subject) || row.subject.toLowerCase().includes(subject.toLowerCase());
                const matchesStatus = !status || row.status === status;
                const matchesCaseNumber = isEmpty(caseNumber) || row.caseNumber.includes(caseNumber);

                //Return true if all filters match, false otherwise
                return matchesDateRange && matchesSubject && matchesStatus && matchesCaseNumber;
            }
        });
        //Update the filtered rows state
        setTableData(filtered);
        setCurrentPage(1);
    }

    const handleClearFilters = () => {
        setDate1(NaN);
        setDate2(NaN);
        setSubject('');
        setStatus('');
        setCaseNumber('');
        setVisible(false);
        setTimeout(() => {
            setVisible(true);
        }, 1);
        //Reset the data to the original unfiltered data
        setTableData(data);
        setCurrentPage(1);
    };

    return (
        
        <div id="myCasesAll">
            <div id="myCasesHead" >
                <h1>My Cases</h1>
                <button id="toggleFilterButton" onClick={handleFilterClick}>{filterText}</button>
            </div>
            {visible && <div id="filterBox">
                <h3 id="filterHead">Filter case results</h3>
                <div className="filtersub">
                    <div id="filterItem">Date range: from-to(MM/dd/YYYY):
                        <ul>
                            <input className="dt"
                                type="date"
                                onChange={handleChangeDate1}
                                ref={dateInputRef}
                            />
                            <input className="dt"
                                type="date"
                                onChange={handleChangeDate2}
                                ref={dateInputRef}
                            />
                        </ul>
                    </div>
                    <div id="filterItem">Subject:
                        <ul>
                            <input className="filterbox"
                                type="text"
                                onChange={handleChangeSubject}
                                ref={dateInputRef}
                                value={ subject }
                            />
                        </ul>
                    </div>
                    <div id="filterItem">Status:
                        <ul>
                            <select className="filterbox"
                                id="dropdown"
                                type="text"
                                onChange={handleChangeStatus}
                                ref={dateInputRef}>
                                <option value="" >--Select--</option>
                                {statusOptions.map(option => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </select>

                        </ul>
                    </div>
                    <div id="filterItem">Case number:
                        <ul>
                            <input className="filterbox"
                                type="text"
                                onChange={handleChangeCaseNumber}
                                ref={dateInputRef}
                                value={ caseNumber }
                            />
                        </ul>
                    </div>
                </div>
                <div id="applyFilter">
                    <button className="btn" onClick={handleClearFilters}>Clear Filters</button>
                    <button className="btn" onClick={handleApplyClick}>Apply Filter</button>
                </div>
            </div>}
            <p></p>
            <div>
                <table id="casesBox">
                    <thead>
                        <tr className="tableheader">
                            <th className="columnname" id="caseNumber" onClick={() => handleSort('caseNumber')}>
                                Case number {sortColumn === 'caseNumber' && (sortDirection === 'asc' ? '▲' : '▼')}
                            
                            </th>
                            <th className="columnname" id="subject" onClick={() => handleSort('subject')}>
                                Subject {sortColumn === 'subject' && (sortDirection === 'asc' ? '▲' : '▼')}
                            </th>
                            <th className="columnname" id="lastUpdated" onClick={() => handleSort('lastUpdated')}>
                                Last updated {sortColumn === 'lastUpdated' && (sortDirection === 'asc' ? '▲' : '▼')}
                            </th>
                            <th className="columnname" id="status" onClick={() => handleSort('status')}>
                                Status {sortColumn === 'status' && (sortDirection === 'asc' ? '▲' : '▼')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedEntries.length === 0 ? (
                            <tr>
                                <td colSpan="4" style={{ textAlign: 'center' }}>No cases found</td>
                            </tr>
                        ) : (
                            displayedEntries.map((row, index) => (
                                <tr
                                    id="caseRow"
                                    key={index}
                                    style={row.status.includes("Completed") ? {} : { color: 'black' }}
                                    className={index % 2 === 1 ? 'alternate-row' : 'regular-row'}
                                    onClick={() => {
                                        localStorage.setItem("caseObject", JSON.stringify(row));
                                        navigate("/update-a-case");
                                    }}
                                >
                                    <td style={{ paddingLeft: '10px' }}>{row.caseNumber}</td>
                                    <td>{row.subject}</td>
                                    <td>{row.lastUpdated}</td>
                                    <td
                                        style={
                                            row.status.includes("Completed")
                                                ? { color: 'black' }
                                                : row.status.includes("Pending")
                                                    ? { color: '#e78c07' }
                                                    : { color: 'green' }
                                        }>
                                        {row.status}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
                <div id="pagination">
                    <div id="pageBar">
                        <button id="pageButton" onClick={() => handlePageChange(1)} disabled={currentPage === 1} className='pageArrow'>
                            {'<<'}
                        </button>
                        <button id="pageButton" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className='pageArrow'>
                        {'<'}
                    </button>
                        {Array.from({ length: Math.min(5, totalPages) }, (_, index) => {
                            let page;
                            if (currentPage <= 2) {
                                page = index + 1;
                            } else if (currentPage >= totalPages - 1 && totalPages > 4) {
                                page = totalPages - 4 + index;
                            } else if (totalPages === 4 && currentPage === 4) {
                                page = currentPage - 3 + index;
                            } else {
                                page = currentPage - 2 + index;
                            }
                            const isActive = page === currentPage && page >= 1 && page <= totalPages;

                            if (page >= 1 && page <= totalPages) {
                                return (
                                    <button
                                        key={page}
                                        onClick={() => handlePageChange(page)}
                                        className={isActive ? 'active' : 'notActive'}
                                        style={{
                                            color: isActive ? 'white' : '#1476B6',
                                            background: isActive ? '#1476B6' : '',
                                        }}
                                        id="pageButton"
                                    >
                                        {page}
                                    </button>
                                );
                            }
                            return null;
                        })}
                        <button id="pageButton" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className='pageArrow'>
                            {'>'}
                        </button>
                        <button id="pageButton" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} className='pageArrow' >
                        {'>>'}
                        </button>

                    </div>
                </div>
                <Footer/>
            </div>
            <Loading
                display={displayloadingDialog}
                title="Loading..."
                description="Your cases are loading"
            />
            <DialogOk
                display={displayOk}
                title="Invalid Filter!"
                ok={ok}
                description="The date you entered is not in the correct format"
                color="red"
            />
        </div>
    );
};


export default MyCases;
