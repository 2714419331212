import React from 'react'
import './Dialog.css';

export function Loading({ display, title, description, color}) {

    if (!display) {
        return <></>;
    }
    const hrlineStyle = {
        color: color
    };
    return (
        <div className="overlay">
            <div className="dialog">
                <div className="dialog_content">
                    <h2 className="dialog_title">{title}</h2>
                    <hr className="hrline" style={hrlineStyle} />
                    <h2 className="line"> </h2>
                    <p className="dialog_description">{description}</p>
                </div>
                <hr className="hr" />
                <div className="dialog_footer">
                </div>
            </div>
        </div>
    )
}

export default Loading;