import React, { useEffect, useState, } from 'react';
import './UpdateCases.css';
import Dialog from './Dialog';
import Attach from './Attach';
import Cookies from 'js-cookie'
import Loading from './Loading';
import DialogOk from './DialogOK';
import { getApiUrl } from './ApiUtil';

export function UpdateCases() {
    const apiUrl = getApiUrl();

    const storedCaseObject = JSON.parse(localStorage.getItem("caseObject"));

    const [attachmentArray, setattachmentArray] = useState([]);
    const [discussionArray, setdiscussionArray] = useState([]);

    const [caseObject, setCaseObject] = useState(storedCaseObject);
    const [displayoldOk, setdisplayoldOk] = useState(false);
    const [replyPlaceholder, setreplyPlaceholder] = useState("Start typing here...");
    const [topic, setTopic] = useState('Loading...');
    const [UUID, setUUID] = useState();

    const [attachData, setAttachData] = useState([]);
    const [attachName, setAttachName] = useState([]);
    const [attachType, setAttachType] = useState([]);

    const [displayloadingDialog, setdisplayloadingDialog] = useState(true);
    const [displaycancelloadingDialog, setdisplaycancelloadingDialog] = useState(false);
    const [displayreopenloadingDialog, setdisplayreopenloadingDialog] = useState(false);
    const [displaycancelDialog, setdisplaycancelDialog] = useState(false);
    const [displayreopenDialog, setdisplayreopenDialog] = useState(false);
    const [displaysubmitDialog, setdisplaysubmitDialog] = useState(false);
    const [displayFieldError, setdisplayFieldError] = useState(false);
    const [displayreplyError, setdisplayreplyError] = useState(false);
    const [displaystatusError, setdisplaystatusError] = useState(false);
    const [displayattachmentError, setdisplayattachmentError] = useState(false);
    
    const [disablesubmitButton, setdisablesubmitButton] = useState(false);
    const [disablereplyText, setdisablereplyText] = useState(false);

    const [attachmentSize, setattachmentSize] = useState(false);
    const [caseCanceled, setcaseCanceled] = useState(false);
    const [submitCheck, setSubmitCheck] = useState(false);

    //these payload state variables are hardcoded for the http request to change the case to either reopened or closed
    const [payload] = useState({
        ServiceRequestUserLifeCycleStatusCode: 'Y9',
        CanceledByCustomer: true
    });
    const [reopenPayload] = useState({
        "ServiceRequestUserLifeCycleStatusCode": "Y4", 
        "CanceledByCustomer": false
    });

    //saves the caseObject data from MyCases in local browser storage, may cause issues depending on the browser
    useEffect(() => {
        localStorage.setItem("caseObject", JSON.stringify(caseObject));
    }, [caseObject]);

    //checks if a case is cancelled
    useEffect(() => {
        if (caseObject.status.toLowerCase().includes("completed") || caseObject.status.toLowerCase().includes("closed")) {
            setcaseCanceled(true)
        }
    }, [caseObject.status]);

    //disables buttons and fields if the case is cancelled or attachments are not formatted correctly
    useEffect(() => {
        if (caseCanceled === true) {
            setdisablereplyText(true);
            setreplyPlaceholder("");
        } else {
            setdisablereplyText(false)
        }
        if (attachmentSize === true || caseCanceled === true) {
            setdisablesubmitButton(true)
        } else {
            setdisablesubmitButton(false)
        }
        if (attachData === [] && caseCanceled === false) {
            setdisablesubmitButton(false);
        }
    }, [attachmentSize, caseCanceled, caseObject.status, attachData]);

    //parses attachment data to binary for http requests
    const receiveDataFromAttach = (data, files, size) => {
        const parseData = (fileData) => {
            const [type, binary] = fileData.split(';base64,')
            return {
                type: type.split(':')[1],
                binary: binary
            }
        }
        const processedTypes = [];
        const processedBinary = [];
        data.forEach((fileData) => {
            processedTypes.push(parseData(fileData).type)
            processedBinary.push(parseData(fileData).binary)

        });
        setAttachType(processedTypes);
        setAttachData(processedBinary);
        setattachmentSize(!size);
        const fileNames = files.map((file) => file.name)
        setAttachName(fileNames)
    };
     
    //makes a patch request when user confirms they want to cancel a case
    //this and the reopen confirm can be combined into one function if you change the logic
    const confirm = async () => {
        setdisplaycancelloadingDialog(true);
        setdisplaycancelDialog(false);
        setdisplayreopenDialog(false);
        try {
            const response = await fetch(`${ apiUrl }/status/${caseObject.objectID}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${Cookies.get('access_token')}`,
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': 'fetch'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                caseObject.status = 'Completed - Canceled'
                caseObject.lastUpdated = new Date();
                setCaseObject({ ...caseObject });
            } else {
                setdisplaycancelloadingDialog(false);
                setdisplaystatusError(true);
                const error = await response.text();
                console.log('PATCH request failed:', error);
            }
        } catch (error) {
            setdisplaycancelloadingDialog(false);
            setdisplaystatusError(true);
            console.log('An error occurred:', error.message);
        }
        setdisplaycancelloadingDialog(false);
    };

    const confirmReopen = async () => {
        const daysDifference = getDateDifferenceInDays(caseObject.lastUpdated);
        if (daysDifference > 30) {
            setdisplayoldOk(true);
            setdisplayreopenDialog(false);
        } else {
            setdisplayreopenloadingDialog(true);
            setdisplayreopenDialog(false);
            setdisplaycancelDialog(false);
            try {
                const response = await fetch(`${apiUrl}/status/${caseObject.objectID}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('access_token')}`,
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': 'fetch'
                    },
                    body: JSON.stringify(reopenPayload)
                });

                if (response.ok) {
                    caseObject.status = 'In Progress - Reopened'
                    caseObject.lastUpdated = new Date();
                    setCaseObject({ ...caseObject });
                    setcaseCanceled(false)
                } else {
                    setdisplayreopenloadingDialog(false);
                    setdisplaystatusError(true);
                    const error = await response.text();
                    console.log('PATCH request failed:', error);
                }
            } catch (error) {
                setdisplayreopenloadingDialog(false);
                setdisplaystatusError(true);
                console.log('An error occurred:', error.message);
            }
            setdisplayreopenloadingDialog(false);
        }

    };
    //cases can only be reopened within 30 days of the last updated date
    function getDateDifferenceInDays(dateString) {
        const lastUpdatedDate = new Date(dateString);
        const currentDate = new Date();
        const differenceInTime = currentDate - lastUpdatedDate;
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        return differenceInDays;
    }

    
    //closes dialogue boxes
    const cancel = () => {
        setdisplaycancelDialog(false);
        setdisplayreopenDialog(false);

    };
    const ok = () => {
        setdisplayoldOk(false);
    };
    const initialValues = { description: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    //handles submitting replies and attachments for cases, checks if the user uploaded attachments and uses the correct post request accordingy
    const handleSubmit = async (e) =>
    {
        setFormErrors(validate(formValues));

        e.preventDefault();
        const replyPayload = {
            "Text": `${formValues.description}`, "TypeCode": "10008", "AuthorUUID": `${UUID}`, "ParentObjectID": `${caseObject.objectID}`
        }
        let didSubmit = false;

        //look into combining these calls
            if (formValues.description !== "") {
                setdisplaysubmitDialog(true);

                try {
                    const response = await fetch(`${apiUrl}/topic/reply`, {
                        method: 'Post',
                        headers: {
                            'Authorization': `Bearer ${Cookies.get('access_token')}`,
                            'Content-Type': 'application/json',
                            'X-CSRF-Token': 'fetch'
                        },
                        body: JSON.stringify(replyPayload)
                    });

                    if (response.ok) {
                        didSubmit = true;
                        if (attachData !== "") {
                            setdisplaysubmitDialog(true);
                            for (let i = 0; i < attachData.length; i++) {
                                const PayloadAttachment = {
                                    Binary: attachData[i],
                                    CategoryCode: "2",
                                    MimeType: attachType[i],
                                    Name: attachName[i],
                                    TypeCode: "10001"
                                }
                                try {
                                    const response = await fetch(`${apiUrl}/status/attachment/${caseObject.objectID}`, {
                                        method: 'Post',
                                        headers: {
                                            'Authorization': `Bearer ${Cookies.get('access_token')}`,
                                            'Content-Type': 'application/json',
                                            'X-CSRF-Token': 'fetch'
                                        },
                                        body: JSON.stringify(PayloadAttachment)
                                    });

                                    if (response.ok) {
                                        didSubmit = true;

                                    } else {
                                        const error = await response.text();
                                        console.log('POST attachment failed:', error);
                                    }
                                } catch (error) {
                                    console.log('An error occurred:', error.message);
                                }
                            }
                        }
                    } else {
                        const error = await response.text();
                        console.log('POST reply failed:', error);
                        setdisplayreplyError(true);

                    }
                } catch (error) {
                    setdisplayreplyError(true);
                    console.log('An error occurred:', error.message);
                }
            }

        
        if (didSubmit === true) {
            setSubmitCheck(true);
            setdisplaysubmitDialog(false);
            window.location.reload();
        } else {
            setdisplayFieldError(true);
            setdisplaysubmitDialog(false);
        }
    };

    async function getEmpData() {
        const response = await fetch(`${apiUrl}/emp`, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`
            }
        });
        const data = await response.text();
        return data;
    }
    async function getTopicData() {
        const response = await fetch(`${apiUrl}/topic/${caseObject.objectID}`, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`
            }
        });
        return response;
    }


    async function getAttachmentData() {
        const response = await fetch(`${apiUrl}/topic/attachment/${caseObject.objectID}`, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`
            }
        });
        return response;
    }
    async function getDiscussionData() {
        const response = await fetch(`${apiUrl}/topic/discussion/${caseObject.objectID}`, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`
            }
        });
        return response;
    }

    //makes a call for the employee's UUID, this is used to identify what author is replying to the case
    //In the future we can change this call to save the UUID to local storage
    useEffect(() => {
        async function fetchData() {
            const empResponse = await getEmpData();
            const empDocument = await new DOMParser().parseFromString(empResponse, "text/xml");
            let UUID = empDocument.getElementsByTagName('d:UUID')[0].textContent;
            setUUID(UUID);
        }
        fetchData();
    }, []);

    //makes a call for the necessary data for the case that isn't carried over from My Cases, specifically the topic, previous attachments, and previous replies
    useEffect(() => {
        if (caseObject.objectID !== null) {
            setSubmitCheck(false);
            async function fetchRemainingData() {
                setdisplayloadingDialog(true);
                await Promise.all([
                    getTopicData().then((response) => {
                        return response.text();
                    }).then((topicData) => {
                        const xmlDocumentTopic = new DOMParser().parseFromString(topicData, "text/xml");
                        const TopicElements = xmlDocumentTopic.querySelectorAll("entry");

                        let Topic = 'Loading...'
                        for (const TopicElement of TopicElements) {
                            Topic = TopicElement.getElementsByTagName('d:content')[0].textContent;
                        }
                        setTopic(Topic);
                    }),
                    getAttachmentData().then((response) => {
                        return response.text();
                    }).then((attachmentData) => {
                        const xmlDocumentAttachments = new DOMParser().parseFromString(attachmentData, "text/xml");
                        const attachmentElements = xmlDocumentAttachments.querySelectorAll("entry");
                        let attachmentArray = []
                        for (const attachmentElement of attachmentElements) {

                            let attachName = attachmentElement.getElementsByTagName('d:Name')[0].textContent;
                            let attachDate = attachmentElement.getElementsByTagName('d:LastUpdatedOn')[0].textContent;
                            let attachLink = attachmentElement.getElementsByTagName('d:LinkWebURI')[0].textContent;
                            const rawAttachDate = new Date(attachDate);
                            const formattedAttachDate = `${rawAttachDate.toDateString()} ${rawAttachDate.toLocaleTimeString()}`;
                            attachDate = formattedAttachDate
                            attachmentArray.push({ attachName, attachDate, attachLink })
                        }
                        setattachmentArray(attachmentArray)
                    }),
                    getDiscussionData().then((response) => {
                        return response.text();
                    }).then((replyData) => {
                        const xmlDocumentDiscussion = new DOMParser().parseFromString(replyData, "text/xml");
                        const discussionElements = xmlDocumentDiscussion.querySelectorAll("entry");
                        let discussionArray = []

                        for (const discussionElement of discussionElements) {
                            let author = discussionElement.getElementsByTagName('d:AuthorName')[0].textContent;
                            if (author === '') {
                                author = discussionElement.getElementsByTagName('d:CreatedBy')[0].textContent;
                            }
                            console.log(author);
                            const firstName = author.split(' ')[0];
                            console.log(author.split(' ')[0])
                                let replyText = discussionElement.getElementsByTagName('d:Text')[0].textContent;
                                let replyDate = discussionElement.getElementsByTagName('d:CreatedOn')[0].textContent;
                                const rawReplyDate = new Date(replyDate);
                                replyDate = `${rawReplyDate.toDateString()}`;


                                discussionArray.push({ firstName, replyText, replyDate })
                        }
                        setdiscussionArray(discussionArray)
                    }),
                ]);
                setdisplayloadingDialog(false);
            }
            fetchRemainingData();
        }
    }, [caseObject.objectId, submitCheck]);

    //used to switch between the replies tab and the attachments tab
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    const okError = () => {
        setdisplayFieldError(false);
        setdisplayreplyError(false);
        setdisplayattachmentError(false);
        setdisplaystatusError(false);
    }
    const validate = (values) => {
        const errors = {};
        if (!values.description) {
            errors.description = "Reply is required!";
            window.scrollTo(0, 0);
        }

        return errors;
    };
    return (
        <div>

            <div className="caseheader">
                <h1 className="casedetail">Case {caseObject.caseNumber}: {caseObject.subject}</h1>
                {caseCanceled ? (
                    <button onClick={() => { setdisplayreopenDialog(true) }} className="cancel-btn" type="button">Reopen Case</button>
                ) : (
                    <button onClick={() => { setdisplaycancelDialog(true) }} className="cancel-btn" type="reset">Cancel Case</button>
                )}
            </div>
            <form className="update-case-header" >
                <div className="update-case-topic">
                    <label>
                        Topic: {topic}
                    </label>
                </div>
                <div className="update-case-status">
                    <label>
                        Status:

                        <span style={caseObject.status.includes("Completed") ? { color: 'black' } : caseObject.status.includes("Pending") ? { color: '#e78c07' } : { color: 'green' }}>
                            {" " + caseObject.status}
                        </span>
                    </label>
                </div>

                <div className="update-case-desc">
                    <h2>
                       <span style={{ color: 'red' }}>*</span> Reply to advisor
                    </h2>
                </div>
                <small> <p style={{ fontWeight: 'bold' }}> {formErrors.description}</p></small>

            </form>
            <div id="updateHalf">
                <textarea rows={4} id="desc" name="description" className="form-control" type="text" value={formValues.description} onChange={handleChange} placeholder={replyPlaceholder} disabled={disablereplyText} />
                <small className="text-muted mb-1">Do not upload Personal Identifiable Information (PII).</small>
                {caseCanceled ? (<div/>) : (
                    <div>
                        <Attach sendDataToParent={receiveDataFromAttach} caseCanceled={caseCanceled} />
                    </div>
                )}

                <button onClick={handleSubmit} className="save-btn" disabled={disablesubmitButton}>Submit</button>
            </div>


            <div className="bloc-tabs">
                <button className={toggleState === 1 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(1)} >
                    Discussions
                </button>
                <button className={toggleState === 2 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(2)} >
                    Attachments
                </button>
            </div>
            <div className="content-tabs">
                <div className={toggleState === 1 ? "content  active-content" : "content"} >
                    <h3>Past Discussions</h3>
                    {discussionArray.map((row, index) => (
                        <div key={index} style={{ marginBottom: '10px' }}>
                            <h4>{row.firstName}</h4>
                            <p style={{ color: 'grey', margin: '5px 0' }}><small>{row.replyDate}</small></p>
                            <p style={{ color: 'black', margin: '5px 0' }}>{row.replyText}</p>
                        </div>
                    ))}

                </div>
                <div className={toggleState === 2 ? "content  active-content" : "content"} >
                    <h2>Existing Attachments</h2>
                    <div>
                        <table id="attachBox">
                            <thead>
                                <tr className="tableheader">
                                    <th className="columndtup">Date Uploaded</th>
                                    <th className="columnfn">File Name</th>
                                    <th className="columnfn">File Link</th>
                                </tr>
                            </thead>
                            <tbody>
                                {attachmentArray.map((row, index) => (
                                    <tr key={index} className="attachContent">
                                        <td>{row.attachDate}</td>
                                        <td>{row.attachName}</td>
                                        <td>  {row.attachLink ? (
                                            <a href={row.attachLink} target="_blank" rel="noreferrer">View</a>
                                        ) : (
                                            ""
                                        )}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Dialog
                display={displaycancelDialog}
                title="Cancel Case"
                confirm={confirm}
                cancel={cancel}
                description="Are you sure you want to cancel this case?"
            />
            <Dialog
                display={displayreopenDialog}
                title="Reopen Case"
                confirm={confirmReopen}
                cancel={cancel}
                description="Are you sure you want to reopen this case?"
            />
            <Loading
                display={displayloadingDialog}
                title="Loading..."
                description="Your case info is loading"
            />
            <Loading
                display={displaysubmitDialog}
                title="Updating Case..."
                description="Your case is being updated, this could take a couple of seconds"
            />
            <Loading
                display={displaycancelloadingDialog}
                title="Canceling Case..."
                description="Your case is being canceled, this could take a couple of seconds"
            />
            <Loading
                display={displayreopenloadingDialog}
                title="Reopening Case..."
                description="Your case is being reopened, this could take a couple of seconds"
            />
            <DialogOk
                display={displayoldOk}
                title="Reopen Not Allowed"
                ok={ok}
                description="This case has been closed for more than 30 days"
                color="red"
            />
            <DialogOk
                display={displayFieldError}
                title="Missing Field"
                ok={okError}
                description={`A reply is required for us to process this submission.`}
                color="red"
            />
            <DialogOk
                display={displaystatusError}
                title="Status Error"
                ok={okError}
                description={`There was an error changing your case status, please try again later`}
                color="red"
            />
            <DialogOk
                display={displayreplyError}
                title="Reply Update Error"
                ok={okError}
                description={`There was an error submitting your reply, please try again later`}
                color="red"
            />
            <DialogOk
                display={displayattachmentError}
                title="Attachment Error"
                ok={okError}
                description={`There was an error submitting your attachments, please try again later`}
                color="red"
            />
        </div>
    );
};


export default UpdateCases;

