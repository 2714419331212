import { OpenACase } from "./components/OpenACase";
import { MyContactInfo } from "./components/MyContactInfo";
import { MyCases } from "./components/MyCases";
import { UpdateCases } from "./components/UpdateCases";

const AppRoutes = [
  {
    index: true,
    path: 'my-cases',
    element: <MyCases />
  },
  {
    path: 'open-a-case',
    element: <OpenACase />
  },
  {
    path: 'contact-info',
    element: <MyContactInfo />
   },
  {
        path: '/update-a-case/',
        element: <UpdateCases />
  }
];

export default AppRoutes;
