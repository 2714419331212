import React, { useState, useEffect } from 'react';
import './MyContactInfo.css';
import Dialog from './Dialog';
import DialogOk from './DialogOK';
import Loading from './Loading';
import Cookies from 'js-cookie'
import { getApiUrl } from './ApiUtil';

export function MyContactInfo() {
    //Add contact info to local storage for Open a Case to use and vice versa
    const apiUrl = getApiUrl();
    const storedContactInfo = JSON.parse(localStorage.getItem("contactInfo"));
    

    const [contactInfo, setcontactInfo] = useState(storedContactInfo);
    const [displaysavingDialog, setdisplaysavingDialog] = useState(false);
    const [displayloadingDialog, setdisplayloadingDialog] = useState(true);
    const [displaysubmitDialog, setdisplaysubmitDialog] = useState(false);
    const [displaysubmitError, setdisplaysubmitError] = useState(false);

    const [mobile, setMobile] = useState();
    const [email, setEmail] = useState();
    const [busphone, setBusphone] = useState();
    const [displaycancelDialog, setdisplaycancelDialog] = useState(false);
    const [objectID, setObjectID] = useState('Loading...');
    const [parentobjectID, setParentObjectID] = useState('Loading...');
    const [payload, setpayload] = useState();
    const [payload2, setpayload2] = useState();
    const [checked_preferredContactMethod, setcheckedPreferredContactMethod] = useState();
    const [prefEmailactive, setPrefEmailactive] = useState(true);
    const [prefPhoneactive, setPrefPhoneactive] = useState(true);
    const [prefBusPhoneactive, setPrefBusPhoneactive] = useState(true);
    const [prefEmail, setPrefEmail] = useState("Email");
    const [prefPhone, setPrefPhone] = useState("Preferred Phone");
    const [prefBusPhone, setPrefBusPhone] = useState("Business Phone");

    //makes a patch requet with the user inputs as the payload to updated the user's contact info
    //2 calls need to be made, one for the preffered contact method (payload2) and the other for changing the actual contact info (payload)
    const handleClick = async () => {

        setdisplaysavingDialog(true);

        try {
            const response = await fetch(`${apiUrl}/emp/pref/${parentobjectID}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${Cookies.get('access_token')}`,
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': 'fetch'
                },
                body: JSON.stringify(payload2)
            });
            if (response.ok) {
                console.log(payload2.PreferredContactMethod_Employee, payload.Mobile)
                contactInfo.preferredContactMethod_Employee = payload2.PreferredContactMethod_Employee
                contactInfo.mobile = payload.Mobile
                console.log(contactInfo);
                setcontactInfo({ ...contactInfo });
                setdisplaysubmitDialog(true);

            } else {
                setdisplaysubmitError(true);
                const error = await response.text();
                console.log('PATCH request failed:', error);
            }
        } catch (error) {
            setdisplaysubmitError(false);
            console.log('An error occurred:', error.message);
        }

        fetch(`${apiUrl}/emp/${objectID}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`,
                'Content-Type': 'application/json',
                'X-CSRF-Token': 'fetch'
            },
            body: JSON.stringify(payload)
        });
        setdisplaysavingDialog(false);
    };

    const cancel = () => {
        setdisplaycancelDialog(false);
    };
    const confirm = () => {
        window.location.reload();
        setdisplaycancelDialog(false);
    };
    const ok = () => {
        setdisplaysubmitDialog(false);
        setdisplaysubmitError(false);
    };

    const [preferred_phone, setpreferred_phone] = useState('')
    const [message, setMessage] = useState('')

    //makes sure that if state variable contactInfo changes then the local storage contactInfo will also be changed
    useEffect(() => {
        localStorage.setItem("contactInfo", JSON.stringify(contactInfo));
    }, [contactInfo]);

    //changes phone number when the field is changed
    //Also checks if the phone number field has a valid phone number
    const handleChange = (e) => {
        const regex = /(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/; /*  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/; */
        if (preferred_phone.trim().length >= 9 && preferred_phone.trim().length <= 20 && regex.test(e.target.value)) {
            setMessage(null)
            setMobile(mobile);
            setpayload({ "Mobile": preferred_phone });
        }

        else {
            setMessage('Enter valid phone number')
        }
        setpreferred_phone(e.target.value);
    };

    async function getData() {
        const response = await fetch(`${apiUrl}/emp`, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`
            }
        });
        const data = await response.text();
        return data;
    }
    //gets and parses employee data
    //sets radio buttons to active or inactive if employee is missing contact methods
    //slightly slow to load, could be optimized but not a high priority
    useEffect(() => {
        async function fetchData() {
            if (storedContactInfo !== null) {
                setObjectID(contactInfo.objectID);
                setParentObjectID(contactInfo.parentobjectID);
                setcheckedPreferredContactMethod(contactInfo.preferredContactMethod_Employee);

                if (contactInfo.mobile !== "" || contactInfo.mobile.type !== undefined) { setMobile(contactInfo.mobile); }
                else {
                    setPrefPhoneactive(false);
                    setPrefPhone("No prefered phone number available");
                    setMobile(contactInfo.mobile);
                }
                if (contactInfo.busphone !== "" || contactInfo.busphone.type !== undefined) {
                    console.log(contactInfo.busphone.type)
                    setBusphone(contactInfo.busphone);
                }
                else {
                    setPrefBusPhoneactive(false)
                    setPrefBusPhone("No business phone number available");
                    setBusphone(contactInfo.busphone);
                }
                if (contactInfo.email !== "" || contactInfo.email.type !== undefined) { setEmail(contactInfo.email); }
                else {
                    setPrefEmailactive(false)
                    setPrefEmail("No email available");
                    setEmail(contactInfo.email);
                }
                setpayload({ "Mobile": contactInfo.mobile });
                setpayload2({
                    PreferredContactMethod_Employee: contactInfo.preferredContactMethod_Employee
                });
                setdisplayloadingDialog(false);

            } else { 
            const response = await getData();
            const xmlDocument = await new DOMParser().parseFromString(response, "text/xml");
            const mobile = xmlDocument.getElementsByTagName('d:Mobile')[0].textContent;
            const email = xmlDocument.getElementsByTagName('d:EMail')[0].textContent;
            const busphone = xmlDocument.getElementsByTagName('d:ZEEBusPhone')[0].textContent;
            const objectID = xmlDocument.getElementsByTagName('d:ObjectID')[0].textContent;
            const parentobjectID = xmlDocument.getElementsByTagName('d:ParentObjectID')[0].textContent;
            const preferredContactMethod_Employee = xmlDocument.getElementsByTagName('d:PreferredContactMethod_Employee')[0].textContent;
            const InternalID = xmlDocument.getElementsByTagName('d:InternalID')[0].textContent;
            const UUID = xmlDocument.getElementsByTagName('d:UUID')[0].textContent;
            const CompanyCode = xmlDocument.getElementsByTagName('d:ZEECompanyCode')[0].textContent;
            if (mobile != "") { setMobile(mobile); }
            else {
                setPrefPhoneactive(false);
                setPrefPhone("No prefered phone number available");
                setMobile(mobile);
            }
            if (busphone != "") { setBusphone(busphone); }
            else {
                setPrefBusPhoneactive(false)
                setPrefBusPhone("No business phone number available");
                setBusphone(busphone);
            }
            if (email != "") { setEmail(email); }
            else {
                setPrefEmailactive(false)
                setPrefEmail("No email available");
                setEmail(email);
                }
                let contactInfo = { email, busphone, mobile, objectID, parentobjectID, preferredContactMethod_Employee, InternalID, UUID, CompanyCode }
                localStorage.setItem("contactInfo", JSON.stringify(contactInfo));
                setcontactInfo(contactInfo);
                setObjectID(objectID);
                setParentObjectID(parentobjectID);
                setcheckedPreferredContactMethod(preferredContactMethod_Employee);
                setpayload({ "Mobile": mobile });
                setpayload2({
                    PreferredContactMethod_Employee: preferredContactMethod_Employee
                });
                setdisplayloadingDialog(false);
            }
            setdisplayloadingDialog(false);
        }
        fetchData();
    }, []);

    const handleRadioChange = (e) => {
        setpayload2({
            PreferredContactMethod_Employee: e.target.value
        });
        setcheckedPreferredContactMethod(e.target.value);
    };

    return (
        <div>
            <h1>My Contact Information</h1>
            <h2>Preferred contact information</h2>
            <form className="pref-contact" >
                <div className="pref-values">
                    <label htmlFor="Email" className="text-muted mb-1">
                        <small>Email:</small>
                    </label>
                    <input value={email} id="Email:" name="Email" className="form-control" type="text" disabled placeholder="you@example.com" />
                </div>
                <div className="pref-values">
                    <label htmlFor="preferred_phone" className="text-muted mb-1">
                        <small>Preferred Phone:</small>
                    </label>
                    <input onChange={handleChange} onBlur={handleChange} defaultValue={mobile} id="preferred_phone" name="preferred_phone" className="form-control" type="text" placeholder="+1 999-999-9999" />
                    {message && <div className='message'>{message} </div>}
                </div>
                <div className="pref-values">
                    <label htmlFor="business_phone" className="text-muted mb-1">
                        <small>Business Phone:</small>
                    </label>
                    <input value={busphone} id="business_phone" name="business_phone" className="form-control" type="text" disabled placeholder="999-999-9999" />
                </div>
            </form>
            <h2>Preferred contact method</h2>
            <form>
                <div className="radio">
                    <label className={`radioalign ${!prefEmailactive ? 'disabled-label' : ''}`}>
                        <input type="radio" value="101" name="pref_cont_m" id="pref_email" className="radiosize" onChange={handleRadioChange} checked={checked_preferredContactMethod === "101"} disabled={!prefEmailactive} />
                        {prefEmail}
                    </label>
                </div>
                <div className="radio">
                    <label className={`radioalign ${!prefPhoneactive ? 'disabled-label' : ''}`}>
                        <input type="radio" value="102" name="pref_cont_m" id="pref_preferred_phone" className="radiosize" onChange={handleRadioChange} checked={checked_preferredContactMethod === "102"} disabled={!prefPhoneactive} />
                        {prefPhone}
                    </label>
                </div>
                <div className="radio">
                    <label className={`radioalign ${!prefBusPhoneactive ? 'disabled-label' : ''}`}>
                        <input type="radio" value="103" name="pref_cont_m" id="pref_business_phone" className="radiosize" onChange={handleRadioChange} checked={checked_preferredContactMethod === "103"} disabled={!prefBusPhoneactive} />
                        {prefBusPhone}
                    </label>
                </div>
            </form>
            <button onClick={() => { setdisplaycancelDialog(true) }} className="cancel-button">Cancel</button>
            <button onClick={handleClick} className="save-button" type="submit">Save</button>

            <Dialog
                display={displaycancelDialog}
                title="Changes Not Saved"
                confirm={confirm}
                cancel={cancel}
                description="Your changes have not been saved. Are you sure you want to cancel?"
            />

            <DialogOk
                display={displaysubmitDialog}
                title="Contact Information Updated"
                ok={ok}
                description="Your contact information has been updated"
                color="green"
            />

            <Loading
                display={displayloadingDialog}
                title="Loading..."
                description="Your Contact Information is being loaded"
            />
            <Loading
                display={displaysavingDialog}
                title="Loading..."
                description="Your Contact Information is being saved"
            />
            <DialogOk
                display={displaysubmitError}
                title="Submit Error"
                ok={ok}
                description={`There was an error submitting your contact info, please try again later`}
                color="red"
            />

        </div>
    );
};

export default MyContactInfo;