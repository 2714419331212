import React, { Component } from 'react';
import Cookies from 'js-cookie';
import {getApiUrl } from '../ApiUtil'

const queryParams = new URLSearchParams(window.location.search);
const code = queryParams.get('code');


//This code was originally written for OAL, and uses class based react instead of hook based like the rest of SSCM
//Convert this to hook based, very low priority
export class Callback extends Component {
    //runs code to get the token from PingFederate and to check if the app is in maintenance mode
	static displayName = Callback.name;
    componentDidMount() {
        const isValid = /^[a-zA-Z0-9_-]+$/.test(code);
        const isLength = code.length <= 50;
        this.retrieveMaintenance();
        if (!code || !isValid || !isLength) {
            console.error('No code parameter found in the URL');
            if (window.location.href.includes("localhost"))
            {
                    window.location.replace(process.env.REACT_APP_REDIRECT_URL + "/" + Cookies.get('url'));
                }else if (window.location.href.includes("stage")) {
                        window.location.replace(process.env.REACT_APP_STAGING_REDIRECT_URL + "/" + Cookies.get('url'));
                    }
                    else {
                        window.location.replace(process.env.REACT_APP_PRODUCTION_REDIRECT_URL + "/" + Cookies.get('url'));
                    }
        } else {
            this.retrieveToken(code);
        }
	}
    objectToQuery = (object) => {
        return new URLSearchParams(object).toString();
    };

    //when loading the case manager after logging in, will display this loading screen
	render() {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    fontSize: '48px', 
                }}
            >
                Loading Case Manager...
            </div>
		);
    }
    //uses the code from PingFederate to validate the log in, if the code is valid it uses the redirect url to send the user back to the appropriate URL for SSCM 
    async retrieveToken(code)
    {
        //this block of code is present in every front end webpage that makes a call to the back end and is neccessary for the code to run locally
        //this code can be refactored to cut down on redundent code
        const apiUrl = getApiUrl();

        try {
            const response = await fetch(`${apiUrl}/auth/` + code);
            if (!response.ok) {
                    throw new Error("HTTP status " + response.status);
                }
                else {
                    let in30Minutes = 1 / 48;
                    let payload = await response.json();
                    Cookies.set('access_token', payload?.access_token, {
                        expires: in30Minutes
                    });
                    Cookies.set('refresh_token', payload?.refresh_token, {
                        expires: in30Minutes
                    });
                    Cookies.set('id_token', payload?.id_token, {
                        expires: in30Minutes
                    });
                const fetchInternalId = fetch(`${apiUrl}/status/emp`, {
                    headers: {
                        'Authorization': `Bearer ${payload?.access_token}`
                    }
                });
                const responseID = await fetchInternalId;
                const internalId = await responseID.json();
                    localStorage.setItem('internalId', internalId);
                if (window.location.href.includes("localhost")){
                    window.location.replace(process.env.REACT_APP_REDIRECT_URL + "/" + Cookies.get('url'));
                }else if (window.location.href.includes("stage")) {
                        window.location.replace(process.env.REACT_APP_STAGING_REDIRECT_URL + "/" + Cookies.get('url'));
                    }
                    else {
                        window.location.replace(process.env.REACT_APP_PRODUCTION_REDIRECT_URL + "/" + Cookies.get('url'));
                    }
                }          
        }
        catch (genericError) {
            console.error(genericError);
        }
    }
    async retrieveMaintenance() {
        const apiUrl = getApiUrl();

        try {
            const response = await fetch(`${apiUrl}/auth/mode`);
            if (!response.ok) {
                throw new Error("HTTP status " + response.status);
            }
            else {
                let payload = await response.json();
                console.log(payload)
                Cookies.set('maintenance', payload);
            }
        }
        catch (genericError) {
            console.error(genericError);
        }
    }
}


