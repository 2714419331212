import React from 'react'
import './Dialog.css';

export function Dialog({ display, title, description, confirm, cancel, color }) {

    if (!display) {
        return <></>;
    }
    const hrlineStyle = {
        color: color
    };
    return (
        <div className="overlay">
            <div className="dialog">
                <div className="dialog_content">
                    <h2 className="dialog_title">{title}</h2>
                    <hr className="hrline" style={ hrlineStyle } />
                    <h2 className="line"> </h2>
                    <p className="dialog_description">{description}</p>
                </div>
                <hr className="hr"/>
                <div className="dialog_footer">
                    <button onClick={confirm} className="dialog_btn">Yes</button>
                    <button onClick={cancel} className="dialog_btn">No</button>
                </div>
            </div>
        </div>
    )
}

export default Dialog;