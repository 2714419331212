import React, { useState, useEffect } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import DialogOk from './DialogOK';
import Dialog from './Dialog';
import Cookies from 'js-cookie';
import { getApiUrl } from './ApiUtil';

export function NavMenu() {
    const logoutUrlD = process.env.REACT_APP_LOGOUT_URL;
    const logoutUrlS = process.env.REACT_APP_STAGING_LOGOUT_URL;
    const logoutUrlP = process.env.REACT_APP_PRODUCTION_LOGOUT_URL;
    const apiUrl = getApiUrl()

    const [collapsed, setCollapsed] = useState(false);
    const [data, setData] = useState([]);
    const [fullName, setFullName] = useState();
    const [timeout, setTimeout] = useState(false);
    const [displayLogoutDialog, setdisplayLogoutDialog] = useState(false);

    const [notification, setNotification] = useState();
    const [internalId, setInternalId] = useState(localStorage.getItem('internalId'));
    
    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };
    const ok = () => {
        window.location.reload();
        setTimeout(false);
    };
    const cancelLogOut = () => {
        setdisplayLogoutDialog(false)
    };
    const okLogOut = () => {
        handleLogOut();
    };
    const LogOutButton = () => {
        setdisplayLogoutDialog(true);
    };



    async function getData() {

        const response = await fetch(`${apiUrl}/user`, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`
            }
        });
        const data = await response.json();
        return data;
    }

    useEffect(() => {
        async function fetchData() {
            const response = await getData();
            setData(response);
            const firstName = response.find(item => item.startsWith('first_name: ')).split(': ')[1];
            const lastName = response.find(item => item.startsWith('last_name: ')).split(': ')[1];
            setFullName(`${firstName} ${lastName}`);
        }
        fetchData();


        //checks very 5 minutes for activity, and then prompts the user to reload and relogin if there is no access token in cookies
        const checkTokenExpiration = setInterval(() => {
            const access_token = Cookies.get('access_token');
            if (!access_token) {
                //Token is not present, likely expired
                setTimeout(true);
                clearInterval(checkTokenExpiration);
            }
        }, 300000); //in milliseconds
        //Clean up the interval when the component unmounts
        return () => clearInterval(checkTokenExpiration);
    }, []);

    //code used to log out multiple windows at once
    useEffect(() => {

        const handleStorageChange = (event) => {
            if (event.key === 'logout') {
                setTimeout(true);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    async function getNotification() {

        const response = await fetch(`${apiUrl}/status/badge/?internalId=` + internalId, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`
            }
        });
        const data = await response.json();
        return data;
    }

    useEffect(() => {
        async function fetchData() {
            if (internalId) {
                const response = await getNotification();
                if (response !== 0) {
                    setNotification(response);
                }
            }
        }
        fetchData();
    }, [internalId]);



    useEffect(() => {
        const intervalId = setInterval(async () => {
            if (internalId) {
                const response = await getNotification(internalId);
                    setNotification(response);
            }
        }, 4000);

        return () => {
            clearInterval(intervalId);
        };
    }, [internalId]);

    const handleLogOut = () => {
        const cookies = document.cookie.split(';');
        const access = Cookies.get('id_token');
        cookies.forEach(cookie => {
            const [name] = cookie.split('=');
            console.log(name);
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
        });
        localStorage.clear();
        localStorage.setItem('logout', Date.now().toString());

        if (window.location.origin.includes("localhost")) {
            window.location.replace(
                logoutUrlD + access
            );
        } else {

            if (window.location.origin.includes('stage')) {
                window.location.replace(
                    logoutUrlS + access
                );
            }
            else {
                window.location.replace(
                    logoutUrlP + access
                );
            }
        }
    };

    return (
        <header id="banner"  >
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                <Collapse className="d-sm-inline-flex flex-sm-row" isOpen={!collapsed} navbar id="navbarLink" >
                    <ul className="navbar-nav flex-grow">
                        <NavLink id="link" to="/my-cases" state={{ internalId: internalId }} tag={Link} className="text-light">
                            <span style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                My Cases{notification > 0 ? (<span style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '50%',
                                    background: '#e78c07',
                                    width: '24px',
                                    height: '24px',
                                    marginLeft: "3px"
                                }}>
                                    {notification > 9 ? '9+' : notification}
                                </span>) : ("")}

                            </span>
                        </NavLink>
                        <NavItem id="link">
                            <NavLink tag={Link} state={{ internalId: internalId }} className="text-light" to="/open-a-case">Open a Case</NavLink>
                        </NavItem>
                        <NavItem id="link">
                            <NavLink tag={Link} state={{ internalId: internalId }} className="text-light" to="/contact-info">My Contact Info</NavLink>
                        </NavItem>
                    </ul>
                </Collapse>
                <NavbarBrand className="text-light" >{fullName}</NavbarBrand>
                <button id="link" className="logOut text-light" onClick={LogOutButton}>Log Out</button>
            </Navbar>
            <DialogOk
                display={timeout}
                title="Session Timed Out"
                ok={ok}
                description={`Your session has timed out! Please log in again to continue managing your cases.`}
                color="red"
            />
            <Dialog
                display={displayLogoutDialog}
                title="Log Out"
                confirm={okLogOut}
                cancel={cancelLogOut}
                description="Are you sure you want to log out? Any unsubmitted changes will not be saved."
            />
        </header>
    );
}

export default NavMenu;
